import Footer from "./components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "./components/NavBar2";
import TopHeader from "./components/TopHeader";
import "./AboutPage.css";
import BreadCrumbSection from "./components/BreadCrumbSection";
import RegistrationPerforma from "../assets/pdf/new22.pdf";
import {Link} from "react-router-dom";

const Admissions = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Admissions" subTitle="2025" bdLink="Admissions"/>

            <div className="section3">
                <div className="container pt-90">
                    {/*<div className="row mb-35">*/}
                    {/*    <div className="col-sm-4">*/}
                    {/*        <a href="/#/Registration-Details" className="btn theme-btn btn-block">*/}
                    {/*            Admissions 2024-25*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="row">
                        <div className="col-sm-12">
                            <h1>Admissions</h1>
                            <div className="double-line-bottom-theme-colored-2"></div>
                        </div>
                    </div>

                    <div className="row" id="myButtons">
                        <div className="col-sm-3 col-xs-12 text-center">
                            <a href="/#/Registration-Details" target="_blank"
                               className="btn theme-btn btn-block myButton_A">
                                Admissions 2025
                            </a>
                        </div>

                        <div className="col-sm-3 col-xs-12 text-center">
                            <a href="/assets/images/add-pam-2023.jpg" target="_blank"
                               className="btn theme-btn btn-block myButton_A">Pamphlet</a>
                        </div>

                        {/*<div className="col-sm-3 col-xs-12 text-center">*/}
                        {/*<a href="/assets/pdf/Fee-Structure-2023-24.pdf"*/}
                        {/*   target="_blank" className="btn btn-block theme-btn myButton_A">Fee Structure</a>*/}
                        {/*</div>*/}

                        {/*<div className="col-sm-3 col-xs-6 text-center">*/}
                        {/*    <Link to="#" className="btn theme-btn btn-block myButton_A">Participate in Counselling</Link>*/}
                        {/*</div>*/}

                        <div className="col-sm-3 col-xs-12 text-center">
                            <a href="/assets/pdf/SBSSU-Admission-Brochure-2023-24.pdf" target="_blank"
                               className="btn theme-btn btn-block myButton_A">Brochure</a>
                        </div>
                    </div>

                    <div className="row">
                        {/*Engineering Section Start*/}
                        <div className="col-sm-12 mb-25">
                            <h3 className="text-theme-colored2">Engineering</h3>
                            <div className="double-line-bottom-theme-colored-2"></div>
                            <div className="row">
                                <div className="col-sm-3 col-xs-6 text-center myImage_A">
                                    <Link to="/BtechCSE">
                                        <div className="imageEffect">
                                            <img src="./assets/images/admission_icons/1.png" className="Image_A"
                                                 width="200"
                                                 height="200" alt="Computer Science and Engg."/>
                                        </div>
                                        <p className="myColor_A">Computer Science and Engg.</p>
                                    </Link>
                                </div>
                                <div className="col-sm-3 col-xs-6 text-center myImage_A">
                                    <Link to="/BtechMechEng">
                                        <div className="imageEffect">
                                            <img src="./assets/images/admission_icons/2.png" className="Image_A"
                                                 width="200"
                                                 height="200" alt="Mechannical Engineering"/>
                                        </div>
                                        <p className="myColor_A">Mechannical Engineering</p>
                                    </Link>
                                </div>
                                <div className="col-sm-3 col-xs-6 text-center myImage_A">
                                    <Link to="/BtechEE">
                                        <div className="imageEffect">
                                            <img src="./assets/images/admission_icons/3.png" className="Image_A"
                                                 width="200"
                                                 height="200" alt="Electrical Engineering"/>
                                        </div>
                                        <p className="myColor_A">Electrical Engineering</p>
                                    </Link>
                                </div>
                                <div className="col-sm-3 col-xs-6 text-center myImage_A">
                                    <Link to="/BtechECE">
                                        <div className="imageEffect">
                                            <img src="./assets/images/admission_icons/4.png" className="Image_A"
                                                 width="200"
                                                 height="200" alt="Electronics & Communication Engineering"/>
                                        </div>
                                        <p className="myColor_A">Electronics & Communication Engineering</p>
                                    </Link>
                                </div>
                                <div className="col-sm-3 col-xs-6 text-center myImage_A">
                                    <Link to="/BtechBioTech">
                                        <div className="imageEffect">
                                            <img src="./assets/images/admission_icons/5.png" className="Image_A"
                                                 width="200"
                                                 height="200" alt="Bio-Technology"/>
                                        </div>
                                        <p className="myColor_A">Bio-Technology</p>
                                    </Link>
                                </div>
                                <div className="col-sm-3 col-xs-6 text-center myImage_A">
                                    <Link to="/BtechCivilEng">
                                        <div className="imageEffect">
                                            <img src="./assets/images/admission_icons/6.png" className="Image_A"
                                                 width="200"
                                                 height="200" alt="Civil Engineering"/>
                                        </div>
                                        <p className="myColor_A">Civil Engineering</p>
                                    </Link>
                                </div>
                                <div className="col-sm-3 col-xs-6 text-center myImage_A">
                                    <Link to="/BtechChemicalEng">
                                        <div className="imageEffect">
                                            <img src="./assets/images/admission_icons/7.png" className="Image_A"
                                                 width="200"
                                                 height="200" alt="Chemical Engineering"/>
                                        </div>
                                        <p className="myColor_A">Chemical Engineering</p>
                                    </Link>
                                </div>
                                <div className="col-sm-3 col-xs-6 text-center myImage_A">
                                    <Link to="/BtechIT">
                                        <div className="imageEffect">
                                            <img src="./assets/images/admission_icons/8.png" className="Image_A"
                                                 width="200"
                                                 height="200" alt="Information Technology"/>
                                        </div>
                                        <p className="myColor_A">Information Technology</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/*Engineering Section END*/}

                        {/*Post Graduate Section Start*/}
                        <div className="col-sm-12 mb-25">
                            <h3 className="text-theme-colored2">Post Graduate Courses</h3>
                            <div className="double-line-bottom-theme-colored-2"></div>
                            <div className="row">
                                {/*<div className="col-sm-3 col-xs-6 text-center myImage_A">*/}
                                {/*    <div className="text-center">*/}
                                {/*        <div className="imageEffect">*/}
                                {/*            <img src="./assets/images/admission_icons/12.jpeg" className="Image_A"*/}
                                {/*                 width="200"*/}
                                {/*                 height="200"*/}
                                {/*                 alt="MTech. Thermal Engineering (Regular)"/>*/}
                                {/*        </div>*/}
                                {/*        <p className="myColor_A">MTech. Thermal Engineering (Regular)</p>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="col-sm-3 col-xs-6 text-center myImage_A">
                                    <Link to="/MtechCSE">
                                        <div className="text-center">
                                            <div className="imageEffect">
                                                <img src="./assets/images/admission_icons/14.png" className="Image_A"
                                                     width="200"
                                                     height="200"
                                                     alt="MTech. Computer Science and Engg."/>
                                            </div>
                                            <p className="myColor_A">MTech. Computer Science and Engg.</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-sm-3 col-xs-6 text-center myImage_A">
                                    <Link to="/MtechME">
                                        <div className="text-center">
                                            <div className="imageEffect">
                                                <img src="./assets/images/admission_icons/15.png" className="Image_A"
                                                     width="200"
                                                     height="200"
                                                     alt="MTech.-Mechanical Engineering"/>
                                            </div>
                                            <p className="myColor_A">MTech.-Mechanical Engineering</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-sm-3 col-xs-6 text-center myImage_A">
                                    <Link to="/MscChemistry">
                                        <div className="text-center">
                                            <div className="imageEffect">
                                                <img src="./assets/images/admission_icons/9.jpeg" className="Image_A"
                                                     width="200"
                                                     height="200"
                                                     alt="M.Sc. Chemistry (Regular)"/>
                                            </div>
                                            <p className="myColor_A">M.Sc. Chemistry (Regular)</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-sm-3 col-xs-6 text-center myImage_A">
                                    <Link to="/MscPhysics">
                                        <div className="text-center">
                                            <div className="imageEffect">
                                                <img src="./assets/images/admission_icons/10.jpeg" className="Image_A"
                                                     width="200"
                                                     height="200"
                                                     alt="M.Sc. Physics (Regular)"/>
                                            </div>
                                            <p className="myColor_A">M.Sc. Physics (Regular)</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-sm-3 col-xs-6 text-center myImage_A">
                                    <Link to="/MscMath">
                                        <div className="text-center">
                                            <div className="imageEffect">
                                                <img src="./assets/images/admission_icons/13.png" className="Image_A"
                                                     width="200"
                                                     height="200"
                                                     alt="M.Sc. Maths (Regular)"/>
                                            </div>
                                            <p className="myColor_A">M.Sc. Maths (Regular)</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-sm-3 col-xs-6 text-center myImage_A">
                                    <Link to="/MbaDigitalMarketing">
                                        <div className="text-center">
                                            <div className="imageEffect">
                                                <img src="./assets/images/admission_icons/11.jpeg" className="Image_A"
                                                     width="200"
                                                     height="200"
                                                     alt="MBA"/>
                                            </div>
                                            <p className="myColor_A">MBA</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/*Post Graduate Section END*/}

                        {/*Graduate Section Start*/}
                        <div className="col-sm-12 mb-25">
                            <h3 className="text-theme-colored2">Graduate Courses</h3>
                            <div className="double-line-bottom-theme-colored-2"></div>
                            <div className="row">
                                <div className="col-sm-3 col-xs-6 text-center myImage_A">
                                    <Link to="/BscAgriculture">
                                        <div className="text-center">
                                            <div className="imageEffect">
                                                <img src="assets/images/admission_icons/16.png" className="Image_A"
                                                     width="200"
                                                     height="200"
                                                     alt="BSc. Agriculture"/>
                                            </div>
                                            <p className="myColor_A">B.Sc. Agriculture</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-sm-3 col-xs-6 text-center myImage_A">
                                    <Link to="/BscNonMed">
                                        <div className="text-center">
                                            <div className="imageEffect">
                                                <img src="./assets/images/admission_icons/17.png" className="Image_A"
                                                     width="200"
                                                     height="200"
                                                     alt="BSc.(Non-Medical)"/>
                                            </div>
                                            <p className="myColor_A">BSc.(Non-Medical)</p>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-sm-3 col-xs-6 text-center myImage_A">
                                    <Link to="/Bca">
                                        <div className="text-center">
                                            <div className="imageEffect">
                                                <img src="./assets/images/admission_icons/18.png" className="Image_A"
                                                     width="200"
                                                     height="200"
                                                     alt="BCA"/>
                                            </div>
                                            <p className="myColor_A">BCA</p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/*Graduate Section END*/}

                        {/*Diploma Section Start*/}
                        <div className="col-sm-12 mb-25">
                            <h3 className="text-theme-colored2">Diploma Courses</h3>
                            <div className="double-line-bottom-theme-colored-2"></div>
                            <div className="row">
                                <div className="col-sm-3 col-xs-6 text-center myImage_A">
                                    <div className="text-center">
                                        <div className="imageEffect">
                                            <img src="./assets/images/admission_icons/19.png" className="Image_A"
                                                 width="200"
                                                 height="200"
                                                 alt="Computer Engineering"/>
                                        </div>
                                        <p className="myColor_A">Computer Engineering</p>
                                    </div>
                                </div>
                                <div className="col-sm-3 col-xs-6 text-center myImage_A">
                                    <div className="text-center">
                                        <div className="imageEffect">
                                            <img src="./assets/images/admission_icons/20.png" className="Image_A"
                                                 width="200"
                                                 height="200"
                                                 alt="Electronics & Communication Engineering"/>
                                        </div>
                                        <p className="myColor_A">Electronics & Communication Engineering</p>
                                    </div>
                                </div>
                                <div className="col-sm-3 col-xs-6 text-center myImage_A">
                                    <div className="text-center">
                                        <div className="imageEffect">
                                            <img src="./assets/images/admission_icons/21.png" className="Image_A"
                                                 width="200"
                                                 height="200"
                                                 alt="Mechanical Engineering"/>
                                        </div>
                                        <p className="myColor_A">Mechanical Engineering</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*Diploma Section END*/}


                        {/*<div className="col-sm-12 mb-25">*/}
                        {/*    <div className="row">*/}
                        {/*        <div className="col-sm-6 mysection">*/}
                        {/*            <h3 className="text-theme-colored2">Masters of Science</h3>*/}
                        {/*            <div className="double-line-bottom-theme-colored-2"></div>*/}
                        {/*            <div className="row">*/}
                        {/*                <div className="col-sm-6">*/}
                        {/*                    <div className="text-center">*/}
                        {/*                        <div className="imageEffect">*/}
                        {/*                            <img src="assets/images/admission_icons/9.jpeg" className="Image_A"*/}
                        {/*                                 width="200"*/}
                        {/*                                 height="200"*/}
                        {/*                                 alt=""/>*/}
                        {/*                        </div>*/}
                        {/*                        <p className="text-theme-colored2">M.Sc. Chemistry</p>*/}
                        {/*                    </div>*/}

                        {/*                </div>*/}
                        {/*                <div className="col-sm-6">*/}
                        {/*                    <div className="text-center">*/}
                        {/*                        <div className="imageEffect">*/}
                        {/*                            <img src="assets/images/admission_icons/10.jpeg" className="Image_A"*/}
                        {/*                                 width="200"*/}
                        {/*                                 height="200"*/}
                        {/*                                 alt=""/>*/}
                        {/*                        </div>*/}
                        {/*                        <p className="text-theme-colored2">M.Sc. Physics</p>*/}
                        {/*                    </div>*/}

                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className="col-sm-6">*/}
                        {/*            <h3 className="text-theme-colored2">Diploma</h3>*/}
                        {/*            <div className="double-line-bottom-theme-colored-2"></div>*/}
                        {/*            <div className="row">*/}
                        {/*                <div className="col-sm-6">*/}
                        {/*                    <div className="text-center">*/}
                        {/*                        <img src="assets/images/admission_icons/1.png" width="200" height="200"*/}
                        {/*                             alt=""/>*/}
                        {/*                        <p className="text-theme-colored2">Mechanical Engineering</p>*/}
                        {/*                    </div>*/}

                        {/*                </div>*/}
                        {/*                <div className="col-sm-6">*/}
                        {/*                    <div className="text-center">*/}
                        {/*                        <img src="assets/images/admission_icons/1.png" width="200" height="200"*/}
                        {/*                             alt=""/>*/}
                        {/*                        <p className="text-theme-colored2">Mechanical Engineering</p>*/}
                        {/*                    </div>*/}

                        {/*                </div>*/}
                        {/*            </div>*/}

                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default Admissions;