// import {BrowserRouter, Routes, Route, HashRouter} from "react-router-dom";
import {Routes, Route, HashRouter} from "react-router-dom";
import {lazy, Suspense} from "react";
import './App.css';

// Under Construction Page
import UnderConstructionPage from "./pages/components/UnderConstructionPage";
import PageNotFound from "./pages/components/PageNotFound";

// import Home from "./pages/Home";
import AboutPage from "./pages/AboutPage";
import SlideImagePage from "./pages/SliderImagePage";
import NoticeBoard from "./pages/NoticeBoard";
import Admissions from "./pages/Admissions";
import Hostels from "./pages/Hostels";
import Dispensary from "./pages/Dispensary";
import TopNews from "./TopNews";

import NCC from "./pages/StudentCorner/NCC";
import NSS from "./pages/StudentCorner/NSS";
import EsterSociety from "./pages/StudentCorner/EsterSociety";
import MusicDramaticsClub from "./pages/StudentCorner/MusicDramaticsClub";

import ViewProfile from "./pages/ViewProfile";

// ----------------------------------------- Terms & Policy -------------------------------------
import PrivacyPolicy from "./terms_policy/PrivacyPolicy";
import RefundPolicy from "./terms_policy/RefundPolicy";
import TermsOfUse from "./terms_policy/TermsOfUse";
// ----------------------------------------- Terms & Policy -------------------------------------

// ----------------------------------------- Online Counselling Start -----------------------------------
import StudentAdmission from "./Forms/Admission";
import Counselling from "./Forms/Counselling/Counselling";
import CounsellingInstructions from "./Forms/Counselling/CounsellingInstructions";
// ----------------------------------------- Online Counselling End -------------------------------------

import ThankYou from "./pages/ThankYouPage/ThankYou";
import ThankYouCounselling from "./pages/ThankYouPage/ThankYouCounselling";

// -----------------------------------------Administration Section Start-------------------------------------
import Vicechancellor from "./pages/Vicechancellor";
import OSD from "./pages/Administration/OSD";
import Registrar from "./pages/Registrar";
import Chairman from "./pages/Chairmanboardofgoverner";
import FinanceCommittee from "./pages/FinanceCommittee";
import BuildingWorkComittee from "./pages/BuildingWorkComittee";
import EquipmentCommittee from "./pages/EquipmentCommittee";
import FacultyCHM from "./pages/FacultyCHM";
import Rti from "./pages/Rti";
import AcademicCouncil from "./pages/Administration/AcademicCouncil";
import GrievanceRedressalCell from "./pages/Administration/GrievanceRedressalCell";
import StudentGrievanceRedressalPortal from "./pages/Administration/StudentGrievanceRedressalPortal";
// -----------------------------------------Administration Section End-------------------------------------

// -----------------------------------------Department Section Start-------------------------------------
import DeptChemistryHumanitiesManagement from "./pages/departments/DeptChemistryHumanitiesManagement";
import DeptPhysics from "./pages/departments/DeptPhysics";
import DeptAppliedMathematics from "./pages/departments/DeptAppliedMathematics";
import DeptChemicalEngineering from "./pages/departments/DeptChemicalEngineering";
import DeptComputerScienceEng from "./pages/departments/DeptComputerScienceEng";
import DeptElectronicsCommEng from "./pages/departments/DeptElectronicsCommEng";
import DeptBioTechnology from "./pages/departments/DeptBioTechnology";
import DeptMechanicalEng from "./pages/departments/DeptMechanicalEng";
import DeptCivilEng from "./pages/departments/DeptCivilEng";
import DeptAgriculture from "./pages/departments/DeptAgriculture";
// -----------------------------------------Department Section End-------------------------------------

// -----------------------------------------Course Section Start-------------------------------------
import BtechCSE from "./pages/courses/BtechCSE";
import BtechECE from "./pages/courses/BtechECE";
import BtechMechEng from "./pages/courses/BtechMechEng";
import BtechBioTech from "./pages/courses/BtechBioTech";
import MtechCSE from "./pages/courses/MtechCSE";
import PhdECE from "./pages/courses/PhdECE";
import BbaMarketing from "./pages/courses/BbaMarketing";
import MscChemistry from "./pages/courses/MscChemistry";
import MbaDigitalMarketing from "./pages/courses/MbaDigitalMarketing";
import MscPhysics from "./pages/courses/MscPhysics";
import BscNonMed from "./pages/courses/BscNonMed";
import MscPhysicsHons from "./pages/courses/MscPhysicsHons";
import MscMath from "./pages/courses/MscMath";
import BtechChemicalEng from "./pages/courses/BtechChemicalEng";
import BtechCivilEng from "./pages/courses/BtechCivilEng";
import BtechIT from "./pages/courses/BtechIT";
import Bca from "./pages/courses/Bca";
import Mca from "./pages/courses/Mca";
import BtechEE from "./pages/courses/BtechEE";
import Bvoc from "./pages/courses/Bvoc";
import MtechME from "./pages/courses/MtechME";
import PhdME from "./pages/courses/PhdME";
import BscAgriculture from "./pages/courses/BscAgriculture";
import PhdCSE from "./pages/courses/PhdCSE";
import PhdBioChemical from "./pages/courses/PhdBioTech";
import StaffResidence from "./pages/StaffResidence";
import Transportation from "./pages/Transportation";
import ShoppingComplex from "./pages/ShoppingComplex";
import DeanAcademicAffairs from "./pages/DeanAcademicAffairs";
import DistributionSeats from "./pages/DistributionSeats";
import AcademicPerforma from "./pages/AcademicPerforma";
// -----------------------------------------Course Section End-------------------------------------

// -----------------------------------------Training & Placement Start-------------------------------------
import IndustrialTraining from "./pages/TrainingPlacement/IndustrialTraining";
import Placements from "./pages/TrainingPlacement/Placements";
import EDC from "./pages/StudentCorner/EDC";
import SAE from "./pages/StudentCorner/SAE";
import BiogenSociety from "./pages/StudentCorner/BiogenSociety";
import AlumniAffairs from "./pages/AlumniAffairs";
import PunjabiClub from "./pages/StudentCorner/PunjabiClub";
import Iste from "./pages/StudentCorner/Iste";
import Library from "./pages/Library";
import SportsClub from "./pages/StudentCorner/SportsClub";
import ComputerCentre from "./pages/ComputerCentre";
import Workshop from "./pages/Workshop";
import DownloadsForStudents from "./pages/DownloadsForStudents";
import DownloadsForStaff from "./pages/DownloadsForStaff";
import ExamSection from "./pages/ExamSection";
import BoardofGovernors from "./pages/BoardofGovernors";
import Chancellor from "./pages/Chancellor";
import Research from "./pages/Research";
import FacilitiesforResearchConsultancy from "./pages/FacilitiesforResearchConsultancy";
import TechnicalExpertiseFacultyMember from "./pages/TechnicalExpertiseFacultyMember";
import DownloadsPage from "./pages/DownloadsPage";
import PhdBioTech from "./pages/courses/PhdBioTech";
// -----------------------------------------Training & Placement End---------------------------------------

// -----------------------------------------University Establishment---------------------------------------
import UniversityEstablishment from "./pages/TransparencyNewLink/UniversityEstablishment";
// -----------------------------------------University Establishment---------------------------------------

import {URL} from "./pages/components/MyModules";
import React, {useState, useEffect} from "react";
import axios from "axios";

/* Anti Ragging Page */
import AntiRagging from "./pages/AntiRagging";
import TestingCSS from "./Testing.module.css";
import ReactLoading from "react-loading";

// import IncubationCentre from './pages/IncubationCentre/IncubationCentre';

/* Home */
const Home = lazy(() => import("./pages/Home"));
// import Home from "./pages/Home";


/* Faculty Profile */
// import FacultyProfile from "./pages/FacultyProfile/FacultyProfile";
const FacultyProfile = lazy(() => import("./pages/FacultyProfile/FacultyProfile"));

const Bienvenue = lazy(() => import("./pages/CampusPulse/Bienvenue"));
const AnnualSportsDay = lazy(() => import("./pages/AnnualSportsDay"));
const TeqFest = lazy(() => import("./pages/TeqFest"));
const TeqFest2023 = lazy(() => import("./pages/TeqFest2023"));
const SuiGeneris = lazy(() => import("./pages/SuiGeneris"));
const IndependenceDay = lazy(() => import("./pages/IndependenceDay"));

const IncubationCentre = lazy(() => import("./pages/IncubationCentre/IncubationCentre"));

// ------------------------------------ RESULT BATCH 2021 ----------------------------------
const ResultOf_First_Sem_Masters = lazy(() => import("./Notice/Result/Batch2021/ResultOf_First_Sem_Masters"));
const ResultOf_ChemistryGroup = lazy(() => import("./Notice/Result/Batch2021/ResultOf_ChemistryGroup"));
const ResultOf_First_Sem2021BCA = lazy(() => import("./Notice/Result/Batch2021/ResultOf_First_Sem2021BCA"));
const ResultOf_Second_Sem2021BCA = lazy(() => import("./Notice/Result/Batch2021/ResultOf_Second_Sem2021BCA"));

// ----------------------------------------- TENDERS ---------------------------------------
const TenderA = lazy(() => import("./Notice/Tenders/TenderA"));
const TenderB = lazy(() => import("./Notice/Tenders/TenderB"));
const TenderC = lazy(() => import("./Notice/Tenders/TenderC"));
const TenderD = lazy(() => import("./Notice/Tenders/TenderD"));
const TenderE = lazy(() => import("./Notice/Tenders/TenderE"));
// ----------------------------------------- TENDERS ---------------------------------------

// ------------------------------------------------- DMC Verify -----------------------------------------------
const Verify = lazy(() => import("./pages/DMC/Verify"));
const DocumentVerify = lazy(() => import("./pages/DMC/DocumentVerify"));
const CreateResultNotification = lazy(() => import("./pages/DMC/CreateResultNotification"));
const SearchResultNotification = lazy(() => import("./pages/DMC/SearchResultNotification"));
const ViewResultNotification = lazy(() => import("./pages/DMC/ViewResultNotification"));
// ------------------------------------------------- DMC Verify -----------------------------------------------

// --------------------------------------------------- BCET Polywing -------------------------------------------
const PolytechnicalWing = lazy(() => import("./pages/BcetPolywing"));
const ContactUs = lazy(() => import("./pages/Polywing/ContactUs"));
const DiplomaInCse = lazy(() => import("./pages/Polywing/DiplomaInCse"));
const DiplomaInEce = lazy(() => import("./pages/Polywing/DiplomaInEce"));
const DiplomaInMe = lazy(() => import("./pages/Polywing/DiplomaInMe"));
const PolytechnicChancellor = lazy(() => import("./pages/Polywing/PolytechnicChancellor"));
const PolytechnicVicechancellor = lazy(() => import("./pages/Polywing/PolytechnicVicechancellor"));
const AboutUs = lazy(() => import("./pages/Polywing/AboutUs"));
const TrainingPlacement = lazy(() => import("./pages/Polywing/TrainingPlacement"));
const PolyAdmissions = lazy(() => import("./pages/Polywing/PolyAdmissions"));
const PolytechnicalPrincipal = lazy(() => import("./pages/Polywing/PolytechnicalPrincipal"));
const WordsByPrincipal = lazy(() => import("././pages/Polywing/WordsByPrincipal"));
// ------------------------------------------------- BCET Polywing -----------------------------------------------

// ----------------------------------------- Pre-Registration Start -----------------------------------
// import RegDetails from "./Forms/PreRegistration/RegDetails";
// import PreRegistration from "./Forms/PreRegistration/PreRegistration";

const RegDetails = lazy(() => import("./Forms/PreRegistration/RegDetails"));
const PreRegistration = lazy(() => import("./Forms/PreRegistration/PreRegistration"));
// ----------------------------------------- Pre-Registration End -------------------------------------

/* Counselling Context */
export const CounsellingContext = React.createContext(null);

function App() {
    let [counselling, setCounselling] = useState(true);
    let [title, setTitle] = useState("");

    useEffect(() => {
        axios.get(`${URL}admin/get-current-counselling-dates`)
            .then(async res => {
                // console.log(res.data);
                let {code, icon, message, data, title} = res.data;

                if (data === "notEnded") {
                    setCounselling(true);
                } else {
                    setCounselling(false);
                }
                setTitle(title);
            }).catch(err => {
            // console.log(err.message);
            setCounselling(false);
            setTitle("");
        });

        // console.log(counselling);
    }, [counselling, title]);

    return (
        <>
            <CounsellingContext.Provider value={{counselling, title}}>
                <Suspense fallback={<div className={TestingCSS.content}>
                    <ReactLoading type='spinningBubbles' color='#C21717'/>
                </div>}>
                    <HashRouter>
                        <Routes>

                            {/*// ----------------------------------- BCET Polywing Start -------------------------------*/}
                            <Route path="/polytechnicalwing" element={<PolytechnicalWing/>}/>
                            <Route path="/contactus" element={<ContactUs/>}/>
                            <Route path="/diplomaincse" element={<DiplomaInCse/>}/>
                            <Route path="/diplomainece" element={<DiplomaInEce/>}/>
                            <Route path="/diplomainme" element={<DiplomaInMe/>}/>
                            <Route path="/polychancellor" element={<PolytechnicChancellor/>}/>
                            <Route path="/polyvicechancellor" element={<PolytechnicVicechancellor/>}/>
                            <Route path="/polyaboutus" element={<AboutUs/>}/>
                            <Route path="/polytrainingplacement" element={<TrainingPlacement/>}/>
                            <Route path="/polyAdmissions" element={<PolyAdmissions/>}/>
                            <Route path="/polytechnicalprincipal" element={<PolytechnicalPrincipal/>}/>
                            <Route path="/wordsbyprincipal" element={<WordsByPrincipal/>}/>
                            {/*// ----------------------------------- BCET Polywing Start -------------------------------*/}

                            {/*// ----------------------------------- DMC Verify -------------------------------*/}
                            <Route path="/DOCUMENT_VERIFY" element={<DocumentVerify/>}/>
                            <Route path="/DMC_VERIFICATION" element={<Verify/>}/>
                            {/*<Route path="/create-result-notification" element={<CreateResultNotification/>}/>*/}
                            <Route path="/search-result-notification" element={<SearchResultNotification/>}/>
                            <Route path="/result-notification" element={<ViewResultNotification/>}/>
                            {/*// ----------------------------------- DMC Verify -------------------------------*/}

                            {/*// -----------------------------------  -------------------------------*/}
                            <Route path="/university-establishment" element={<UniversityEstablishment/>}/>

                            {/*// ----------------------------------- Result Batch 2021 -----------------------------*/}
                            <Route
                                path="/result-notifications-of-1st-Sem-regular-(Batch-2021)-ME,MCA,MSc-Chem,MSc-Phy,MSc-Maths"
                                element={<ResultOf_First_Sem_Masters/>}/>
                            <Route path="/result-notifications-of-2nd-Sem-regular-(Batch-2021)-Chemistry-Group"
                                   element={<ResultOf_ChemistryGroup/>}/>
                            <Route
                                path="/result-notifications-of-1st-Sem-regular-(Batch-2021)-B.Sc-(NM),BBA,BVOC,BCA-and-BSc-Agri"
                                element={<ResultOf_First_Sem2021BCA/>}/>
                            <Route
                                path="/result-notifications-of-2nd-Sem-regular-(Batch-2021)-B.Sc-(NM),BBA,BVOC,BCA-and-BSc-Agri"
                                element={<ResultOf_Second_Sem2021BCA/>}/>

                            {/*// ----------------------------------- Tender Notice -------------------------------*/}
                            <Route path="/Tender-Category-A" element={<TenderA/>}/>
                            <Route path="/Tender-Category-B" element={<TenderB/>}/>
                            <Route path="/Tender-Category-C" element={<TenderC/>}/>
                            <Route path="/Tender-Category-D" element={<TenderD/>}/>
                            <Route path="/Tender-Category-E" element={<TenderE/>}/>
                            {/*// ------------------------------------ Tender Notice --------------------------------*/}

                            {/*// ----------------------------------- Incubation Centre -------------------------------*/}
                            <Route path="/incubation-centre" element={<IncubationCentre/>}/>
                            {/*// ------------------------------------ Incubation Centre --------------------------------*/}

                            {/*// ----------------------------------- Faculty Profile Start -------------------------------*/}
                            <Route path="/Faculty-Profile/:id" element={<FacultyProfile/>}/>
                            {/*// ----------------------------------- Faculty Profile End -------------------------------*/}

                            {/*// ----------------------------------- Terms & Policy -------------------------------*/}
                            <Route path="/Privacy-Policy" element={<PrivacyPolicy/>}/>
                            <Route path="/Refund-Policy" element={<RefundPolicy/>}/>
                            <Route path="/Terms-Of-Use" element={<TermsOfUse/>}/>
                            {/*// ----------------------------------- Terms & Policy -------------------------------*/}

                            {/*// ----------------------------------- Route for :- Under Construction Page Start -------------------------------*/}
                            {/* <Route path="/incubation-centre" element={<UnderConstructionPage/>}/> */}
                            <Route path="/under-construction" element={<UnderConstructionPage/>}/>
                            <Route path="/underconstructionpage" element={<UnderConstructionPage/>}/>
                            {/*// ------------------------------------ Route for :- Under Construction Page End --------------------------------*/}

                            {/*// ------------------------------------ Route for :- Ragging Start --------------------------------*/}
                            <Route path="/Anti-Ragging" element={<AntiRagging/>}/>
                            {/*// ------------------------------------ Route for :- Ragging End --------------------------------*/}

                            {/*// ----------------------------------------- Student Admission Form Start -------------------------------------*/}

                            {/* Online-Counselling */}
                            {/*{counselling && */}
                            {/*<Route path='/Online-Counselling' element={<StudentAdmission/>}/>*/}
                            {/*}*/}

                            {/*<Route path='/Online-Counselling-Instructions' element={<CounsellingInstructions/>}/>*/}
                            {/*<Route path='/Online-Counselling' element={<Counselling/>}/>*/}
                            {/*<Route path='/Thank-you-for-counselling' element={<ThankYouCounselling/>}/>*/}

                            {counselling && <>
                                <Route path='/Registration-Details' element={<RegDetails/>}/>
                                <Route path='/Pre-Registration' element={<PreRegistration/>}/>
                                <Route path='/Thank-You' element={<ThankYou/>}/>
                            </>}

                            {/*// ----------------------------------------- Student Admission Form End --------------------------------------*/}

                            {/*// ----------------------------------------- Profile Page --------------------------------------*/}
                            <Route path="/ViewProfile/:id" element={<ViewProfile/>}/>
                            {/*// ----------------------------------------- // Profile Page --------------------------------------*/}

                            <Route path="/" element={<Home/>}/>
                            <Route path="/slidepage" element={<SlideImagePage/>}/>
                            <Route path="/aboutus" element={<AboutPage/>}/>
                            <Route path="/homepageslider" element={<Home/>}/>
                            <Route path='/notice-board/:id' element={<NoticeBoard/>}/>
                            {/*<Route path='/notice-board' element={<NoticeBoard/>}/>*/}
                            <Route path='/top-news' element={<TopNews/>}/>
                            <Route path='/hostels' element={<Hostels/>}/>
                            <Route path='/dispensary' element={<Dispensary/>}/>
                            <Route path='/staffresidence' element={<StaffResidence/>}/>
                            <Route path='/transportation' element={<Transportation/>}/>
                            <Route path='/shoppingcomplex' element={<ShoppingComplex/>}/>
                            <Route path='/distributionofseats' element={<DistributionSeats/>}/>
                            <Route path='/academicperforma' element={<AcademicPerforma/>}/>
                            <Route path='/alumniaffairs' element={<AlumniAffairs/>}/>
                            <Route path='/library' element={<Library/>}/>
                            <Route path='/computercenter' element={<ComputerCentre/>}/>
                            <Route path='/workshop' element={<Workshop/>}/>
                            <Route path='/downloadsforstudent' element={<DownloadsForStudents/>}/>
                            <Route path='/downloadsforstaff' element={<DownloadsForStaff/>}/>
                            <Route path='/ExamSection' element={<ExamSection/>}/>
                            <Route path='/boardofgovernors' element={<BoardofGovernors/>}/>
                            <Route path='/chancellor' element={<Chancellor/>}/>
                            <Route path='/research' element={<Research/>}/>
                            <Route path='/facilities_res_cons' element={<FacilitiesforResearchConsultancy/>}/>
                            <Route path='/technicalexpertise' element={<TechnicalExpertiseFacultyMember/>}/>
                            <Route path='/downloadspage' element={<DownloadsPage/>}/>

                            {/*// ----------------------------------------- CAMPUS PULSE -------------------------------------*/}
                            <Route path="/Bienvenue" element={<Bienvenue/>}/>
                            <Route path="/AnnualSportsDay" element={<AnnualSportsDay/>}/>
                            <Route path="/TeqFest" element={<TeqFest/>}/>
                            <Route path="/TeqFest2023" element={<TeqFest2023/>}/>
                            <Route path="/SuiGeneris" element={<SuiGeneris/>}/>
                            <Route path="/IndependenceDay" element={<IndependenceDay/>}/>
                            {/*// ----------------------------------------- CAMPUS PULSE -------------------------------------*/}

                            {/*// -----------------------------------------Administration Section Start-------------------------------------*/}
                            <Route path='/admissionpage' element={<Admissions/>}/>
                            <Route path="/osd-to-vice-chancellor" element={<OSD/>}/>
                            <Route path="/grievanceredressalcell" element={<GrievanceRedressalCell/>}/>
                            <Route path="/grievance" element={<StudentGrievanceRedressalPortal/>}/>
                            <Route path="/vicechancellor" element={<Vicechancellor/>}/>
                            <Route path="/registrar" element={<Registrar/>}/>
                            {/*<Route path="/chairman" element={<Chairman/>}/>*/}
                            <Route path="/chairman" element={<Vicechancellor/>}/>
                            <Route path="/financecomittee" element={<FinanceCommittee/>}/>
                            <Route path="/buildingworkcomittee" element={<BuildingWorkComittee/>}/>
                            <Route path="/equipmentcomittee" element={<EquipmentCommittee/>}/>
                            <Route path='/facultychm' element={<FacultyCHM/>}/>
                            <Route path="/rti" element={<Rti/>}/>
                            <Route path="/academic-council" element={<AcademicCouncil/>}/>
                            {/*// -----------------------------------------Administration Section End-------------------------------------*/}

                            {/*// -----------------------------------------Department Section Start-------------------------------------*/}
                            <Route path='/DeptChemistryHumanitiesManagement'
                                   element={<DeptChemistryHumanitiesManagement/>}/>
                            <Route path='/DeptPhysics' element={<DeptPhysics/>}/>
                            <Route path='/DeptAppliedMathematics' element={<DeptAppliedMathematics/>}/>
                            <Route path='/DeptChemicalEngineering' element={<DeptChemicalEngineering/>}/>
                            <Route path='/DeptComputerScienceEng' element={<DeptComputerScienceEng/>}/>
                            <Route path='/DeptElectronicsCommEng' element={<DeptElectronicsCommEng/>}/>
                            <Route path='/DeptBioTechnology' element={<DeptBioTechnology/>}/>
                            <Route path='/DeptMechanicalEng' element={<DeptMechanicalEng/>}/>
                            <Route path='/DeptCivilEng' element={<DeptCivilEng/>}/>
                            <Route path='/DeptAgriculture' element={<DeptAgriculture/>}/>
                            {/*// -----------------------------------------Department Section End-------------------------------------*/}

                            {/*// -----------------------------------------Courses Section Start-------------------------------------*/}
                            <Route path='/BtechCSE' element={<BtechCSE/>}/>
                            <Route path='/BtechECE' element={<BtechECE/>}/>
                            <Route path='/BtechMechEng' element={<BtechMechEng/>}/>
                            <Route path='/BtechBioTech' element={<BtechBioTech/>}/>
                            <Route path='/MtechCSE' element={<MtechCSE/>}/>
                            <Route path='/PhdECE' element={<PhdECE/>}/>
                            <Route path='/BbaMarketing' element={<BbaMarketing/>}/>
                            <Route path='/MscChemistry' element={<MscChemistry/>}/>
                            <Route path='/MbaDigitalMarketing' element={<MbaDigitalMarketing/>}/>
                            <Route path='/MscPhysics' element={<MscPhysics/>}/>
                            <Route path='/MscPhysicsHons' element={<MscPhysicsHons/>}/>
                            <Route path='/BscNonMed' element={<BscNonMed/>}/>
                            <Route path='/MscMath' element={<MscMath/>}/>
                            <Route path='/BtechChemicalEng' element={<BtechChemicalEng/>}/>
                            <Route path='/BtechCivilEng' element={<BtechCivilEng/>}/>
                            <Route path='/BtechIT' element={<BtechIT/>}/>
                            <Route path='/Bca' element={<Bca/>}/>
                            <Route path='/Mca' element={<Mca/>}/>
                            <Route path='/BtechEE' element={<BtechEE/>}/>
                            <Route path='/Bvoc' element={<Bvoc/>}/>
                            <Route path='/MtechME' element={<MtechME/>}/>
                            <Route path='/PhdME' element={<PhdME/>}/>
                            <Route path='/BscAgriculture' element={<BscAgriculture/>}/>
                            <Route path='/PhdCSE' element={<PhdCSE/>}/>
                            <Route path='/Phd-Chemical-Engineering-Bio-Technology' element={<PhdBioChemical/>}/>
                            {/*// -----------------------------------------Courses Section End-------------------------------------*/}

                            <Route path='/DeanAcademicAffairs' element={<DeanAcademicAffairs/>}/>

                            {/*// -----------------------------------------Student Corner Start-------------------------------------*/}
                            <Route path="/MusicDramaticsClub" element={<MusicDramaticsClub/>}/>
                            <Route path="/EsterSociety" element={<EsterSociety/>}/>
                            <Route path="/NCC" element={<NCC/>}/>
                            <Route path="/NSS" element={<NSS/>}/>
                            <Route path="/EDC" element={<EDC/>}/>
                            <Route path="/SAE" element={<SAE/>}/>
                            <Route path="/BioGenSociety" element={<BiogenSociety/>}/>
                            <Route path="/punjabiculture" element={<PunjabiClub/>}/>
                            <Route path="/iste" element={<Iste/>}/>
                            <Route path="/sportsclub" element={<SportsClub/>}/>
                            {/*// -----------------------------------------Student Corner End-------------------------------------*/}

                            {/*// -----------------------------------------Training Placements Start-------------------------------------*/}
                            <Route path='/industrial-training' element={<IndustrialTraining/>}/>
                            <Route path='/placements' element={<Placements/>}/>
                            {/*// -----------------------------------------Training Placements End-------------------------------------*/}

                            {/*// ----------------------------------- Route for :- 404 Page Not Found Start -------------------------------*/}
                            <Route path="*" element={<PageNotFound/>}/>
                            {/*// ------------------------------------ Route for :- 404 Page Not Found End --------------------------------*/}

                        </Routes>
                    </HashRouter>
                </Suspense>
            </CounsellingContext.Provider>
        </>
    );
}

export default App;
