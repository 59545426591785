import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import NavBar2 from "../components/NavBar2";
import TopHeader from "../components/TopHeader";
import "../AboutPage.css";
import BreadCrumbSection from "../components/BreadCrumbSection";

const MscChemistry = (props) => {

    return (
        <>
            <NavBar2/>
            <BreadCrumbSection mainTitle="Postgraduate Courses" subTitle="M.Sc(Chemistry)" bdLink="Courses"/>

            <div className="section3">
                <div className="container pt-90">
                    {/*  Main Area  */}
                    <div className="row m-7-v">
                        <h1 className="text-theme-colored2">Master of Science in Chemistry </h1>
                        <div className="double-line-bottom-theme-colored-2"></div>
                        {/*  Details  */}
                        <div className="row">
                            <div className="col-md-8  padd-10-v">
                                <p className=" font-p-v m-7-v">
                                    The department of Applied Chemistry, Humanities & Management offers two years
                                    postgraduate course of M.Sc. degree in the discipline of Chemistry. In this
                                    two--year degree programme, students will learn theory and practical in the areas of
                                    Chemistry such as academic specialization within topics of Applied theoretical
                                    chemistry, Organic chemistry or Structural chemistry.Through lectures, laboratory
                                    work, exercises, project work, excursions, students will gain knowledge about
                                    relevant working methods for research, industry, administration and education. The
                                    Master's degree program in Chemistry also forms the foundation for doctoral programs
                                    in Chemistry.
                                </p>

                            </div>
                            <div className="col-md-4 text-center">
                                <div className="imageEffect-v">
                                    <img src="./assets/images/admission_icons/btech-ce.jpeg" className="Image_A"
                                         alt="M.Sc. Physics (Regular)"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  Eligibility */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Eligibility Criteria</h2>
                            <p className="font-p-v m-7-v">
                                All those candidates who have passed B.Sc. or equivalent from a recognized University
                                with Chemistry as one of the subject with at least 50% marks in aggregate for General
                                category and 45% for SC/ST candidates.
                            </p>
                        </div>
                    </div>

                    {/*  Duration  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Duration</h2>
                            <p className="font-p-v m-7-v">
                                The Course will be 2 years long, with Four Semesters
                            </p>
                        </div>
                    </div>

                    {/*  Syllabus  */}
                    <div className="padd-10-v">
                        <div className="row">
                            <h2 className="text-theme-colored2 m-7-v ">Syllabus</h2>
                            <p className="font-p-v m-7-v">
                                <table className="table-hover table margin-25-V">
                                    <thead className="table-head-red-V">
                                    <tr>
                                        <th className="table-title-V padd-l-10">Scheme</th>
                                        <th className="table-title-V padd-l-36">Syllabus</th>
                                        <th className="table-title-V">Remarks</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {/* 2023 */}
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2023</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/admissions/PG/MSC/Chemistry/MSc-Chemistry-syllabus-and-scheme.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i><span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2023 Onwards</td>
                                    </tr>

                                    {/* 2021 */}
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2021</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/msc-chem-2021.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i><span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2021 Onwards</td>
                                    </tr>

                                    {/* 2018 */}
                                    <tr>
                                        <td className="table-body-padd-V syllabus-table-v">2018</td>
                                        <td className="table-body-padd-V syllabus-table-v"><a
                                            className="btn margin-x-25-v theme_btn_V theme-btn"
                                            href="/assets/pdf/msc-chem-2018.pdf"
                                            target="_blank"><i className="fa fa-file-pdf-o padd-10-v"></i> <span
                                            className="hide-v"> Download
                                            Syllabus</span></a></td>
                                        <td className="table-body-padd-V syllabus-table-v">Batch 2018 Onwards</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </p>
                        </div>
                    </div>

                </div>
            </div>
            <Footer/>
        </>
    );
}

export default MscChemistry;